* {
  margin: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 48px;
}

.show-recapcha {
  display: block !important;
}

.hide-recapcha {
  display: none !important;
}
